@use 'variables' as *

@mixin color-set($name, $color)
    .#{$name}
        color: $color
    .bg-#{$name}
        background-color: $color
    .b--#{$name}
        border-color: $color

    .hover-#{$name}:hover,
    .hover-#{$name}:focus
        color: $color

    .hover-bg-#{$name}:hover,
    .hover-bg-#{$name}:focus
        background-color: $color

@mixin gradient-set($name, $color-start, $color-end)
    .bg-#{$name}-gradient
        background-image: linear-gradient(to right, $color-start, $color-end)

@include color-set('satys-accent', $satys-accent)
@include color-set('satys-dark-accent', $satys-dark-accent)
@include color-set('satys-light-accent', $satys-light-accent)
@include gradient-set('satys', $satys-purple, $satys-red)
@include gradient-set('satys-light', $satys-light-purple, $satys-light-red)


.background-with-slide
    background-size: 200% 100%
    background-image: linear-gradient(to left, $satys-accent 50%, white 50%)
    transition: all 250ms
    cursor: default

.background-with-slide:hover,
.background-with-slide:focus
    background-position: -100% 0
    border-color: $satys-purple
    color: white
