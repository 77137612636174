/* From https://unpkg.com/bpmn-js@5.0.3/dist/assets/diagram-js.css */

.djs-group {
    user-select: none;
}

.djs-group .user {
    cursor: pointer;
}

.djs-group .user.hover .djs-visual > rect {
    fill: rgba(200,200,200,0.4) !important;
}

.djs-group .user.selected .djs-visual > rect {
    stroke: #ba2e6d !important;
    fill: rgba(186,46,109,.2) !important;
}

.layer-base > .djs-group > *:not(.user) {
    opacity: 0.3;
}
