@use 'variables' as *
@use 'sass:color'

.card.save-as
    filter: none
    border: 2px solid $light-grey
    pointer-events: none

    .noprint,
    .card-options-icon,
    .tooltip.kpi-label,
    .insight-feedback-wrapper,
    .card-info,
    .card-tabs,
    .card-options-icon,
    .card-options-menu
        display: none !important

    &.type-image img
        width: 100%

    .card-grid
        display: initial

    .labels, .overlays, .responses-labels, .answer-labels
        opacity: 1 !important

    .graphcontainer
        .overlays, .labels
            font-size: 16px

        .tooltip
            display: none

        svg
            .axis,
            .label,
            .labels
                color: var(--satys-text-color)
                fill: var(--satys-text-color)

.type-analyses
    .card.save-as
        .navigation
            display: none !important

        .analyses-accordion .analyses
            display: initial
            flex-direction: column
            height: auto

        .analysis
            flex-basis: unset !important
            padding: 1rem 0
            widows: 2
            orphans: 2
            break-inside: avoid !important

.type-smart_summary
    .card.save-as
        .entries
            display: grid
            grid-template-columns: repeat(3, 1fr)
            gap: 3rem

            .entry
                margin-right: 0 !important

.type-open_question
    .card.save-as
        .table-graph .graphgrid
            max-height: unset

            .row .column.date
                opacity: 1

.type-choice_question
    .card.save-as
        .flex
            flex-direction: column

            > div
                flex-shrink: 1
                padding: 0 1rem 0 3rem

            > div:not(:last-child)
                margin-bottom: 2rem

.type-priorities
    .card.save-as
        .prioritymatrix
            flex-wrap: nowrap

            svg .quadrants text
                font-size: 1.5rem

            .prioritymatrix-legend .values
                margin: 0

.type-labels_score
    .card.save-as
        .label-score-body
            max-height: none

            .score,
            .label__start,
            .label__end,
            .label__score-container .label__score,
            .label__required-value-container .label__required-value
                opacity: 1

.type-stacked_comparison
    .card.save-as
        svg .text
            opacity: 1
