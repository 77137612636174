.collapsible-list,
.arrow.asc, .arrow.desc {
    position: relative;
}
.arrow.asc::after,
.arrow.desc::after {
    content: '';
    position: absolute;
    margin-top: 5px;
    margin-left: 5px;
    width: 0;
    height: 0;

}
.collapsible-list::before {
    content: '';
    position: absolute;
    margin-top: 6px;
    margin-left: -20px;
    width: 0;
    height: 0;
}
.collapsible-list.closed::before {
    margin-left: -15px;
    margin-top: 4px;
    border-left: 5px solid black;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
}
.collapsible-list.opened::before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
}

.arrow.asc::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black;
}
.arrow.desc::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid black;
}
