@use 'variables' as *
@use 'sass:color'

html.save-as
    *
        -webkit-print-color-adjust: exact
        print-color-adjust: exact
        pointer-events: none

    .noprint
        display: none !important

    .daterange span
        display: none

    .tooltip.kpi-label
        display: none

    .type-analyses
        .navigation
            display: none !important

        .analyses-accordion .analyses
            display: initial
            flex-direction: column
            height: auto

        .analysis
            flex-basis: unset !important
            padding: 1rem 0
            widows: 2
            orphans: 2
            break-inside: avoid !important

    .type-open_question .table-graph .graphgrid
        max-height: unset

        .row .column.date
            opacity: 1

    .type-choice_question .flex
        flex-direction: column

        > div
            flex-shrink: 1
            padding: 0 1rem 0 3rem

        > div:not(:last-child)
            margin-bottom: 2rem

    .noprint, .expand
        display: none !important

    .card-grid .col-center-8
        grid-column-start: 1
        grid-column-end: 12

    .pe-icon.pe-icon--regular.dropdown,
    .card-grid form,
    .answer-filter,
    .daterange .pe-button:not(.pe-button--selected)
        display: none

    .card
        filter: none
        border: 2px solid $light-grey

        &.type-image img
            width: 100%

        .card-grid
            display: initial

        .card-info,
        .card-tabs,
        .card-options-icon,
        .card-options-menu,
        .insight-feedback-wrapper
            display: none !important

    .labels, .overlays, .responses-labels, .answer-labels
        opacity: 1 !important

    .graphcontainer
        .overlays, .labels
            font-size: 16px

        .tooltip
            display: none

        svg
            .axis,
            .label,
            .labels
                color: var(--satys-text-color)
                fill: var(--satys-text-color)
