@use 'variables' as *
@use 'sass:color'

#profile

    .tabs
        flex-grow: 0

        &.tabshadow
            box-shadow: 2px 0px 8px -3px rgba(0, 0, 0, .2)

        .pe-list-tile
            &.active
                .pe-list-tile__primary
                    border-right: 2px solid $satys-accent
                    color: $satys-accent
                    font-weight: bold

            .pe-list-tile__primary
                color: rgba(0, 0, 0, .4)
                text-align: right
                border-right: 2px solid transparent

                &:hover, &:focus, &:active
                    border-right: 2px solid $satys-accent
                    color: $satys-accent
