@use 'variables' as *
@use 'sass:color'

#modal
    position: fixed
    left: 0
    right: 0
    top: 0
    bottom: 0
    background: color.adjust(black, $alpha: -0.4)
    display: flex
    align-items: center
    z-index: 2147483647 // max val

    .content
        max-height: calc(100% - 5rem)
        background: $satys-light-accent
        color: color.adjust(black, $lightness:+10%)
        max-width: 32rem
        position: relative
        margin: auto
        border-radius: 1rem
        display: flex
        flex-direction: column
        .header
            padding: 1.25rem 1.5rem
            border-bottom: 1px solid lightgray
            h2
                margin: 0
                font-size: 1.25rem
            .close_btn
                position: absolute
                width: 1rem
                height: 1rem
                text-align: center
                right: .5rem
                top: .5rem
                padding: .5rem
                border-radius: 100%

        .body
            padding: 2rem 1.5rem
            border-bottom: 1px solid lightgray

        .footer
            line-height: 1rem
            padding: 0.5rem 1.5rem
            border: none
            .close_btn
                border-radius: 0.25rem
                padding: 0.5rem .875rem
                margin-right: -1rem

        .close_btn
            font-size: .875rem
            text-transform: uppercase
            cursor: pointer
            float: right
            transition: background ease-in 100ms
            color: $satys-accent
            font-weight: bold
            &:hover
                background: color.adjust($satys-accent, $alpha: -0.95)


// Custom styling for dialog component
.pe-dialog-pane
    .pe-dialog-pane__content
        overflow-y: auto
        overflow-x: hidden

    .pe-dialog-pane__body
        overflow-y: inherit
        overflow-x: inherit

